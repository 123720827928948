import Dialog from 'components/dist/atoms/Dialog';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import { SvgSpinner } from 'src/components/common/svg-spinner';

export const SwitchingRolesDialog = () => <Dialog open>
    <Dialog.Content
        className='w-full h-full sm:w-full sm:h-full bg-black-primary md:w-full md:h-full sm:max-w-full sm:max-h-full rounded-none sm:rounded-none'
    >
        <Stack space='md' items='center' justify='center' className='flex-1'>
            <SvgSpinner className='text-white' />
            <Text size="sm" variant='white'>
                Switching roles, please wait...
            </Text>
        </Stack>
    </Dialog.Content>
</Dialog>