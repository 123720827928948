import { useRouter } from "next/router";
import React from "react";
import { LoanDto } from "src/backend";
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { useGetLoanByIdQuery, useSearchLoansQuery } from "src/services/loanApi";
import { useDebounceValue } from "usehooks-ts";


export const useSearchAutocomplete = () => {
    const router = useRouter();
    const [query, setQuery] = React.useState<string>('');
    const loanId = router.query[QUERY_PARAM_LOAN_ID] as string;
    const { data: loan } = useGetLoanByIdQuery(loanId, {
        skip: !loanId
    })
    const [debouncedQuery] = useDebounceValue(query, 500);

    const { data = { items: [] }, isFetching } = useSearchLoansQuery({
        q: debouncedQuery,
        p: 0,
        d: 'd',
        c: loan?.id ?? ''
    }, {
        skip: debouncedQuery.length === 0
    })

    return {
        placeholder: getByPlaceholderText(loan),
        results: data,
        isFetching,
        onClearSearchClick: () => {
            setQuery('');
        },
        onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
        },
        query,
        noResults: !isFetching && data?.items.length === 0 && debouncedQuery.length > 0
    }
};

const getByPlaceholderText = (loan?: LoanDto): string => {
    if (loan) {
        return `Search ${loan.projectName}`
    }

    return `Search anything`
}